import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import {
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { RETTER_RELEVOS_QUERY_KEY } from '../../api/apiConsts';
import { ErrorMessages } from '../../common/components/ErrorMessages/ErrorMessages';
import { useRestaurantsData } from '../../services/restaurants';
import { useCreateRetterRelevo, useRetterRelevos } from '../../services/retter-relevos';
import { RetterRelevoFormModel } from './form/retter-relevo-form';
import { RetterRelevosCreatedModal } from './modals/RetterRelevosCreatedModal';
import { RetterRelevosOfferLimitReachedModal } from './modals/RetterRelevosOfferLimitReachedModal';
import { RetterRelevoRequest } from './model/retter-relevo-request';
import { RETTER_RELEVOS_DEFAULT_TABLE_LIMIT } from './utils/retter-relevos.consts';
import { RetterRelevoForm } from './RetterRelevoForm';

interface CreateRetterRelevoProps {
  triggerButton: React.ReactNode;
  retterRelevo?: RetterRelevoFormModel;
}

export const CreateRetterRelevo = ({ retterRelevo, triggerButton }: CreateRetterRelevoProps) => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  const { restaurants } = useRestaurantsData();

  const {
    isOpen: isCreateFormModalOpen,
    onClose: onCreateFormModalClose,
    onOpen: onCreateFormModalOpen,
  } = useDisclosure();
  const { isOpen: isSuccessModalOpen, onClose: onSuccessModalClose, onOpen: onSuccessModalOpen } = useDisclosure();
  const {
    isOpen: isLimitReachedModalOpen,
    onClose: onLimitReachedModalClose,
    onOpen: onLimitReachedModalOpen,
  } = useDisclosure();
  const {
    mutate: createRetterRelevo,
    isLoading: isCreatingRetterRelevo,
    isError: isCreateRetterRelevoError,
  } = useCreateRetterRelevo();
  const { refetch: fetchRetterRelevos } = useRetterRelevos(0, RETTER_RELEVOS_DEFAULT_TABLE_LIMIT, true, false);

  const [restaurantsNames, setRestaurantsNames] = useState<string[]>([]);
  const onSubmit = (retterRelevoRequest: RetterRelevoRequest) => {
    createRetterRelevo(retterRelevoRequest, {
      onSuccess: async (data) => {
        setRestaurantsNames(data.restaurants.map((restaurant) => restaurant.name));
        onSuccessModalOpen();
        await queryClient.invalidateQueries(RETTER_RELEVOS_QUERY_KEY);
        onCreateFormModalClose();
      },
      onError: (err) => {
        toast.error(<ErrorMessages errors={err} />);
      },
    });
  };

  const handleButtonClick = async () => {
    try {
      const response = await fetchRetterRelevos();
      if (response.data) {
        const restaurantIdsFromContext = restaurants.map((restaurant) => restaurant.id);
        const uniqueRestaurantIdsFromOffers = Array.from(
          new Set(response.data.items.flatMap((item) => item.restaurantIds)),
        );
        const allRestaurantIdsInUniqueArray = restaurantIdsFromContext.every((id) =>
          uniqueRestaurantIdsFromOffers.includes(id),
        );
        if (allRestaurantIdsInUniqueArray) {
          onLimitReachedModalOpen();
          return;
        }
      }
      onCreateFormModalOpen();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <>
      {triggerButton &&
        React.cloneElement(triggerButton as React.ReactElement, {
          onClick: handleButtonClick,
        })}
      <Modal isOpen={isCreateFormModalOpen} onClose={onCreateFormModalClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent h="auto" maxH="80%" minH="20%" position="fixed" bottom="1%" right="1%" transform="none">
          <ModalHeader>{translations('retter_relevos_create_modal_header')}</ModalHeader>
          <ModalCloseButton />
          <Flex h="100%" flexDirection="column" px={5} mb={5} flex="1" overflowY="auto">
            <RetterRelevoForm
              isLoading={isCreatingRetterRelevo}
              isError={isCreateRetterRelevoError}
              onSubmit={onSubmit}
              onCancel={onCreateFormModalClose}
              confirmButtonText={translations('retter_relevo_create_submit_button')}
              model={retterRelevo}
            />
          </Flex>
        </ModalContent>
      </Modal>
      <RetterRelevosOfferLimitReachedModal isOpen={isLimitReachedModalOpen} onClose={onLimitReachedModalClose} />
      <RetterRelevosCreatedModal
        isOpen={isSuccessModalOpen}
        restaurantsNames={restaurantsNames}
        onClose={onSuccessModalClose}
      />
    </>
  );
};
