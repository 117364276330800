import React, { JSX } from 'react';
import ReactDatepicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'contexts/LocalizationContext';
import de from 'date-fns/locale/de';

import { FileInput } from '../../common/components/FileInput';
import {
  ImagePreviewValidation,
  isFileTooLarge,
} from '../../common/components/ImagePreviewValidation/ImagePreviewValidation';
import RestaurantMultiSelect from '../../common/components/RestaurantSelect/RestaurantMultiSelect';
import { SelectOption } from '../../common/components/Select/model/selectOption';
import { selectStyle } from '../../common/components/Select/select-style';
import config from '../../config/config';
import { LOCALIZATION_CONFIG } from '../../config/configConsts';
import { useRestaurantsData } from '../../services/restaurants';
import colors from '../../styles/colors';
import { EUR_CURRENCY_SYMBOL } from '../../utils/money.consts';
import { RetterRelevoFormModel } from './form/retter-relevo-form';
import { useRetterRelevoFormValidation } from './form/retter-relevo-form-validation';
import { RetterRelevoRequest } from './model/retter-relevo-request';
import { formatDateForRetterRelevos } from './utils/retter-relevos.dates';
import { generateTimeframeOptions } from './utils/retter-relevos-timeframes.helper';

type RetterRelevoFormProps = {
  isLoading: boolean;
  isError: boolean;
  confirmButtonText: string;
  onSubmit(data: RetterRelevoRequest): void;
  onCancel(): void;
  model?: RetterRelevoFormModel | undefined;
};

const timeframeOptions = generateTimeframeOptions();
export const RetterRelevoForm = ({
  onSubmit,
  model,
  isLoading,
  isError,
  onCancel,
  confirmButtonText,
}: RetterRelevoFormProps): JSX.Element => {
  const translations = useTranslations();
  const { restaurants } = useRestaurantsData();
  const validationSchema = useRetterRelevoFormValidation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<RetterRelevoFormModel>({
    defaultValues: {
      restaurantIds: restaurants.map((r) => r.id),
      pickupDate: new Date(),
    },
    resolver: yupResolver(validationSchema),
  });

  const imageNamePreview: string | undefined = watch('temporaryImage')?.[0]?.name;
  const imageSizePreview: number | undefined = watch('temporaryImage')?.[0]?.size;
  const imageId: string | null | undefined = watch('imageId');
  const imageUrl = getValues('imageUrl');

  React.useEffect(() => {
    if (model && !isLoading && !isError) {
      reset({
        ...model,
      });
    }
  }, [reset, model, isLoading, isError]);

  const onFormSubmit = handleSubmit(({ temporaryImage, ...form }: RetterRelevoFormModel) => {
    onSubmit({
      ...form,
      image: temporaryImage?.[0],
    });
  });

  const handleDeleteUploadedImage = () => setValue('temporaryImage', undefined);
  const handleDeleteExistingImage = () => setValue('imageId', null);

  return (
    <form onSubmit={onFormSubmit}>
      <Box>
        <Box>
          <Text mt={3} mb={2} fontSize={'medium'} fontWeight={'bold'}>
            {translations('retter_relevo_form_locations')}*
          </Text>
          <FormControl isInvalid={!!errors.restaurantIds} mb={4}>
            <Controller
              control={control}
              name="restaurantIds"
              render={({ field: { value, onChange } }) => (
                <RestaurantMultiSelect
                  selectedRestaurantIds={value}
                  userRestaurants={restaurants}
                  onSelectRestaurants={(e) => onChange(e.map((v) => v.value))}
                />
              )}
            />
            <FormErrorMessage>{errors.restaurantIds && errors.restaurantIds.message}</FormErrorMessage>
          </FormControl>
          <Text mt={3} mb={2} fontSize={'medium'} fontWeight={'bold'}>
            {translations('retter_relevo_form_dish_details')}
          </Text>
          <FormControl isInvalid={!!errors.title} mb={4}>
            <FormLabel htmlFor={'title'}>{translations('retter_relevo_form_title')}*</FormLabel>
            <Input
              _focus={{ borderColor: colors.orange[500], boxShadow: 'none' }}
              boxShadow={'none'}
              variant="flushed"
              borderBottomWidth="1px"
              borderColor={colors.grey[200]}
              placeholder={translations('retter_relevo_form_title_placeholder')}
              id={'title'}
              {...register('title')}
            />
            <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.price} mb={4}>
            <FormLabel htmlFor={'price'}>{translations('retter_relevo_form_price')}</FormLabel>
            <Controller
              control={control}
              name="price"
              render={({ field: { value, onChange } }) => (
                <InputGroup>
                  <InputLeftAddon children={EUR_CURRENCY_SYMBOL} />
                  <NumberInput
                    // step={1}
                    precision={2}
                    onChange={(valueString) => onChange(valueString)}
                    value={value || undefined}
                    min={1}
                    max={999}
                    width="100%"
                  >
                    <NumberInputField
                      {...register('price')}
                      _focus={{ borderColor: colors.orange[500], boxShadow: 'none' }}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </InputGroup>
              )}
            />
            <FormErrorMessage>{errors.price && errors.price.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.availableAmount} mb={4}>
            <FormLabel htmlFor={'availableAmount'}>{translations('retter_relevo_form_amount')}</FormLabel>
            <Controller
              control={control}
              name="availableAmount"
              render={({ field: { value, onChange } }) => (
                <InputGroup>
                  <NumberInput
                    step={1}
                    onChange={(valueString) => onChange(valueString)}
                    value={value || undefined}
                    min={0}
                    max={999}
                    width="100%"
                  >
                    <NumberInputField
                      {...register('availableAmount')}
                      _focus={{ borderColor: colors.orange[500], boxShadow: 'none' }}
                    />
                    <NumberInputStepper>
                      <NumberIncrementStepper />
                      <NumberDecrementStepper />
                    </NumberInputStepper>
                  </NumberInput>
                </InputGroup>
              )}
            />
            <FormErrorMessage>{errors.availableAmount && errors.availableAmount.message}</FormErrorMessage>
          </FormControl>
          <FormControl mb={4}>
            <FormLabel htmlFor="image">{translations('retter_relevo_form_image')}</FormLabel>
            {imageUrl && imageId && !imageNamePreview ? (
              <Box>
                <Image id="image" src={imageUrl} />
                <Button variant="redSolid" width="100%" my={2} onClick={handleDeleteExistingImage}>
                  {translations('retter_relevo_form_image_delete_button')}
                </Button>
              </Box>
            ) : null}
            <FileInput
              isFullWidth={true}
              accept="image/*"
              register={register('temporaryImage')}
              aria-label="temporaryImage"
              uploadButtonLabel={translations('retter_relevo_image_form_upload_button')}
            />
            {imageNamePreview && imageSizePreview ? (
              <Box mt={2}>
                <ImagePreviewValidation
                  imageNamePreview={imageNamePreview}
                  imageSizePreview={imageSizePreview}
                  handleDeleteUploadedImage={handleDeleteUploadedImage}
                />
              </Box>
            ) : null}
          </FormControl>
          <Text mt={3} mb={2} fontSize={'medium'} fontWeight={'bold'}>
            {translations('retter_relevo_form_pickup_details')}
          </Text>
          <FormControl isInvalid={!!errors.pickupDate} mb={4}>
            <FormLabel htmlFor="pickupDate">{translations('retter_relevo_form_pickup_date')}*</FormLabel>
            <Controller
              name={'pickupDate'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <ReactDatepicker
                  locale={de}
                  selected={value}
                  customInput={<Input _focus={{ boxShadow: 'none' }} {...register('pickupDate')} />}
                  dateFormat={LOCALIZATION_CONFIG[config.language].dateFormat}
                  onChange={onChange}
                  value={formatDateForRetterRelevos(translations, value)}
                  portalId="datepicker-portal"
                />
              )}
            />
            <FormErrorMessage>{errors.pickupDate && errors.pickupDate.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.pickupTimeFrom || !!errors.pickupTimeTo} mb={4}>
            <FormLabel>{translations('retter_relevo_form_pickup_timeframe')}*</FormLabel>
            <Stack direction="row" align="center" spacing={4}>
              <Box flex="1">
                <Controller
                  name={'pickupTimeFrom'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select<SelectOption, false>
                      value={timeframeOptions.find((option) => option.value === value) || null}
                      styles={selectStyle}
                      options={timeframeOptions}
                      onChange={(selectedOption) => {
                        onChange(selectedOption?.value || null);
                      }}
                    />
                  )}
                />
              </Box>
              <Text fontSize="sm" alignSelf="center">
                {translations('retter_relevo_form_pickup_timeframe_to')}
              </Text>
              <Box flex="1">
                <Controller
                  name={'pickupTimeTo'}
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <Select<SelectOption, false>
                      value={timeframeOptions.find((option) => option.value === value) || null}
                      styles={selectStyle}
                      options={timeframeOptions}
                      onChange={(selectedOption) => {
                        onChange(selectedOption?.value || null);
                      }}
                    />
                  )}
                />
              </Box>
            </Stack>
            <FormErrorMessage>
              {(errors.pickupTimeFrom && errors.pickupTimeFrom.message) ||
                (errors.pickupTimeTo && errors.pickupTimeTo.message)}
            </FormErrorMessage>
            <FormHelperText fontSize={'small'} textAlign="left" mt={2}>
              {translations('retter_relevo_form_pickup_timeframe_helper')}
            </FormHelperText>
          </FormControl>
          <FormControl isInvalid={!!errors.pickupInstructions} mb={4}>
            <FormLabel htmlFor={'pickupInstructions'}>
              {translations('retter_relevo_form_pickup_instructions')}
            </FormLabel>
            <Input
              _focus={{ borderColor: colors.orange[500], boxShadow: 'none' }}
              boxShadow={'none'}
              variant="flushed"
              borderBottomWidth="1px"
              borderColor={colors.grey[200]}
              placeholder={translations('retter_relevo_pickup_instructions')}
              id={'pickupInstructions'}
              {...register('pickupInstructions')}
            />
            <FormErrorMessage>{errors.pickupInstructions && errors.pickupInstructions.message}</FormErrorMessage>
          </FormControl>
        </Box>
        <Box position="sticky" bottom="0" zIndex="1" background={'white'}>
          <Button
            variant="orangeSolid"
            type="submit"
            width="100%"
            isLoading={isLoading}
            mt={4}
            isDisabled={isLoading || isSubmitting || !!(imageSizePreview && isFileTooLarge(imageSizePreview))}
          >
            {confirmButtonText}
          </Button>
          <Button variant="beigeSolid" width="100%" onClick={onCancel} mt={4}>
            {translations('retter_relevo_form_cancel')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};
