import React from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from '@chakra-ui/react';

import { useTranslations } from '../../../../contexts/LocalizationContext';
import colors from '../../../../styles/colors';
import { useReturnCodesContext } from '../../context/hooks/useReturnCodesContext';
import { ReturnCodesLocation } from '../../context/model/ReturnCodesLocation';
import { ReturnCodesResult } from '../../context/model/ReturnCodesResult';
import { visibleScrollStyle } from '../../utils';
import { ReturnCodesManageModalActiveCode } from './ReturnCodesManageModalActiveCode';
import { ReturnCodesManageModalLastDeactivatedCode } from './ReturnCodesManageModalLastDeactivatedCode';

type ReturnCodesManageModalProps = {
  returnCodesData?: ReturnCodesResult;
  isReturnCodesDataFetching: boolean;
  refetchReturnCodes: () => void;
  refetchLocations: () => void;
};

export const ReturnCodesManageModal = ({
  returnCodesData,
  isReturnCodesDataFetching,
  refetchReturnCodes,
  refetchLocations,
}: ReturnCodesManageModalProps) => {
  const translations = useTranslations();
  const { returnCodesState, handleOpenCreateModalAndOverrideLocation, handleCloseManageModal } =
    useReturnCodesContext();

  const location = returnCodesState.locations.find((l) => l.locationId === returnCodesState.locationIdToManage);
  const handleCreateNewReturnCodeButtonClick = (rcLocation: ReturnCodesLocation) => () =>
    handleOpenCreateModalAndOverrideLocation({
      locationId: rcLocation?.locationId,
      locationName: rcLocation?.locationName,
      locationMetaCategories: rcLocation?.metaCategories,
    });

  return (
    <Modal
      isOpen={returnCodesState.isManageModalOpen}
      onClose={() => handleCloseManageModal()}
      size={['sm', 'sm', 'md', 'md', 'md']}
    >
      <ModalOverlay />
      <ModalContent
        h="auto"
        maxH="80vh"
        minH="20vh"
        position="fixed"
        bottom="1%"
        right="1%"
        transform="none"
        overflow="hidden"
        display="flex"
        flexDirection="column"
      >
        <ModalHeader>{translations('return_codes_manage_modal_header')}</ModalHeader>
        <ModalCloseButton />
        {isReturnCodesDataFetching && <Spinner />}
        <Flex direction="column" p={4} flex="1" position="relative" overflow="auto">
          <Box borderRadius="md" padding={4} bg={colors.grey[40]} mb={'10px'}>
            <Text fontSize={'medium'} fontWeight={'bold'}>
              {location?.locationName}
            </Text>
          </Box>

          {(returnCodesData?.returnCodes || []).length > 0 ? (
            <Text fontSize={'small'} mb={'10px'} mx={4}>
              {translations('return_codes_manage_modal_description')}
            </Text>
          ) : (
            <Box borderRadius="md" padding={4} bg={colors.red[50]} mb={'10px'}>
              <Text fontSize={'small'}>
                {translations('return_codes_manage_modal_no_active_return_code_description')}
              </Text>
            </Box>
          )}

          {(returnCodesData?.returnCodes || []).length > 0 && (
            <>
              <Text mb={2} ml={4} fontSize={'large'} fontWeight={'bold'}>
                {(returnCodesData?.returnCodes || []).length === 1
                  ? translations('return_codes_manage_modal_active_code')
                  : translations('return_codes_manage_modal_active_codes')}
              </Text>
              <Box
                maxH="350px"
                minH="50px"
                h="auto"
                overflowY="scroll"
                borderColor="grey.200"
                mb={5}
                sx={visibleScrollStyle}
              >
                {returnCodesData?.returnCodes.map((returnCode) => (
                  <ReturnCodesManageModalActiveCode
                    activeCode={returnCode}
                    refetchLocations={refetchLocations}
                    refetchReuseCodes={refetchReturnCodes}
                    isLastActive={returnCodesData?.returnCodes.length === 1}
                  ></ReturnCodesManageModalActiveCode>
                ))}
              </Box>
            </>
          )}
          {returnCodesData?.lastDeactivatedCode && (
            <ReturnCodesManageModalLastDeactivatedCode
              deactivatedCode={returnCodesData?.lastDeactivatedCode}
              lastDeactivatedCodeHeader={translations('return_codes_manage_modal_last_deactivated_code')}
            ></ReturnCodesManageModalLastDeactivatedCode>
          )}

          <Box position="sticky" bottom={0} left={0} right={0} pb={2} mt="auto">
            {location && (
              <Button width={'100%'} variant="orangeSolid" onClick={handleCreateNewReturnCodeButtonClick(location)}>
                {translations('return_codes_manage_modal_create_new_return_code')}
              </Button>
            )}
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
