import React from 'react';
import { Box, HStack, Image, Text } from '@chakra-ui/react';

import { getIconPath } from '../../../../common/components/Navbar/Menu/utils/menuHelper';
import { useTranslations } from '../../../../contexts/LocalizationContext';
import colors from '../../../../styles/colors';
import { DeactivatedReturnCode } from '../../context/model/ReturnCodesResult';
import { stringToDate } from '../../utils';

type ReturnCodesManageModalLastDeactivatedCodeProps = {
  deactivatedCode: DeactivatedReturnCode;
  lastDeactivatedCodeHeader: string;
};

export const ReturnCodesManageModalLastDeactivatedCode = ({
  deactivatedCode,
  lastDeactivatedCodeHeader,
}: ReturnCodesManageModalLastDeactivatedCodeProps) => {
  const translations = useTranslations();

  return (
    <>
      <Text mb={2} ml={4} fontSize={'large'} fontWeight={'bold'}>
        {lastDeactivatedCodeHeader}
      </Text>
      <Box
        key={deactivatedCode.name}
        borderRadius="md"
        padding={4}
        bg={colors.grey[40]}
        mb={'10px'}
        color={colors.grey[200]}
      >
        <HStack justify="space-between" mb={2}>
          <Text fontWeight={'bold'} fontSize={'medium'}>
            {deactivatedCode.name}
          </Text>
          <Image src={getIconPath('qr_code_2_gray200.svg')}></Image>
        </HStack>
        <Text>
          {translations('return_codes_manage_modal_deactivated_at')}: {stringToDate(deactivatedCode.deactivatedAt)}
        </Text>
        <Text>
          {translations('return_codes_manage_modal_type')}: {deactivatedCode.type}
        </Text>
      </Box>
    </>
  );
};
