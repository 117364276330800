import React, { JSX } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { AxiosError } from 'axios';

import { useTranslations } from '../../../contexts/LocalizationContext';
import { HttpException } from '../../models/httpException';

type ErrorMessagesProps = {
  errors: (AxiosError<HttpException> | null)[] | AxiosError<HttpException> | null;
};

export const ErrorMessages = ({ errors }: ErrorMessagesProps): JSX.Element | null => {
  const translations = useTranslations();
  const errorsArray = Array.isArray(errors) ? errors : [errors];
  const nonNullErrors = errorsArray.filter(
    (possibleError): possibleError is AxiosError<HttpException> => !!possibleError,
  );

  if (!nonNullErrors.length) {
    return null;
  }

  if (!nonNullErrors.some((error) => error?.response?.data?.message)) {
    return <Text>{translations('something_went_wrong')}</Text>;
  }

  return (
    <>
      {nonNullErrors.map((error, index) => (
        <React.Fragment key={index}>
          {Array.isArray(error?.response?.data?.message) ? (
            <Box>{error?.response?.data.message.map((message: string) => <Text key={message}>{message}</Text>)}</Box>
          ) : (
            <Text>{error?.response?.data?.message}</Text>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
