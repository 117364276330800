import { QueryObserverResult, useMutation, UseMutationResult, useQuery, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

import { RETTER_RELEVOS_QUERY_KEY } from '../api/apiConsts';
import {
  createRetterRelevo,
  deleteRetterRelevo,
  getRetterRelevo,
  getRetterRelevos,
  updateRetterRelevo,
} from '../api/retter-relevos';
import { HttpException } from '../common/models/httpException';
import { Paginated } from '../common/models/paginated';
import { useTranslations } from '../contexts/LocalizationContext';
import { RetterRelevoRequest } from '../modules/RetterRelevos/model/retter-relevo-request';
import { RetterRelevoResponse } from '../modules/RetterRelevos/model/retter-relevo-response';

export const useRetterRelevos = (
  offset: number,
  limit: number,
  isActive?: boolean,
  enabled = true,
): QueryObserverResult<Paginated<RetterRelevoResponse>, AxiosError> => {
  return useQuery([RETTER_RELEVOS_QUERY_KEY, isActive], () => getRetterRelevos(offset, limit, isActive), {
    refetchOnWindowFocus: false,
    keepPreviousData: true,
    enabled: enabled,
  });
};

export const useRetterRelevo = (retterRelevoId: string): QueryObserverResult<RetterRelevoResponse, AxiosError> => {
  return useQuery([RETTER_RELEVOS_QUERY_KEY, retterRelevoId], () => getRetterRelevo(retterRelevoId), {
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    enabled: false,
  });
};
export const useCreateRetterRelevo = (): UseMutationResult<
  RetterRelevoResponse,
  AxiosError<HttpException>,
  RetterRelevoRequest
> => {
  return useMutation((retterRelevoRequest) => createRetterRelevo(retterRelevoRequest));
};

export const useUpdateRetterRelevo = (): UseMutationResult<
  RetterRelevoResponse,
  AxiosError<HttpException>,
  { retterRelevoId: string; retterRelevoRequest: RetterRelevoRequest }
> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  return useMutation(
    ({ retterRelevoId, retterRelevoRequest }: { retterRelevoId: string; retterRelevoRequest: RetterRelevoRequest }) =>
      updateRetterRelevo(retterRelevoId, retterRelevoRequest),
    {
      onSuccess: async () => {
        toast.success(translations('retter_relevo_successfully_updated'));
        await queryClient.invalidateQueries(RETTER_RELEVOS_QUERY_KEY);
      },
    },
  );
};
export const useDeleteRetterRelevo = (): UseMutationResult<
  void,
  AxiosError<HttpException>,
  { retterRelevoId: string }
> => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  return useMutation(({ retterRelevoId }: { retterRelevoId: string }) => deleteRetterRelevo(retterRelevoId), {
    onSuccess: async () => {
      toast.success(translations('retter_relevo_successfully_deleted'));
      await queryClient.invalidateQueries(RETTER_RELEVOS_QUERY_KEY);
    },
    onError: (err) => {
      toast.error(err.response?.data.message || translations('something_went_wrong'));
    },
  });
};
