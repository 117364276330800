import React from 'react';
import { Image, Text } from '@chakra-ui/react';
import { differenceInDays, parseISO } from 'date-fns';

import { getIconPath } from '../../../../common/components/Navbar/Menu/utils/menuHelper';
import colors from '../../../../styles/colors';
import { stringToDate } from '../../utils';
import { ReturnCodesTableRow } from './model/return-codes-table-row';

export const lastUpdated = (lastUpdatedDate: string, noActiveTranslation: string) => {
  if (!lastUpdatedDate) {
    return (
      <Text color={'red'} fontWeight={'bold'}>
        {noActiveTranslation}
      </Text>
    );
  }

  const daysDiff = differenceInDays(new Date(), parseISO(lastUpdatedDate));

  let borderCSS: string = '';

  if (daysDiff > 60) {
    borderCSS = 'solid red';
  } else if (daysDiff > 30) {
    borderCSS = `solid ${colors.orange['500']}`;
  }

  return (
    <div
      style={{
        border: borderCSS,
        borderRadius: 15,
        padding: '0 2px 0 2px',
      }}
    >
      <Text className={'ql-bg-red'}>{stringToDate(lastUpdatedDate)}</Text>
    </div>
  );
};

export const activeCodesCount = (
  count: number,
  codesNotUsedForLast30Days: ReturnCodesTableRow['codesNotUsedForLast30Days'],
  unusedOnOpen: () => void,
  noReturnCodeOnOpen: () => void,
) => {
  if (Number(count) === 0) {
    return (
      <>
        <Image src={getIconPath('qr_code_red.svg')} onClick={noReturnCodeOnOpen} cursor={'pointer'}></Image>
        <Text color={'red'} fontWeight={'bold'} ml={1}>
          x0
        </Text>
      </>
    );
  } else if (codesNotUsedForLast30Days.length > 0) {
    return (
      <>
        <Image src={getIconPath('qr_code_orange.svg')} onClick={unusedOnOpen} cursor={'pointer'}></Image>
        <Text ml={1}>x{count}</Text>
      </>
    );
  } else {
    return (
      <>
        <Image src={getIconPath('qr_code_black.svg')}></Image>
        <Text ml={1}>x{count}</Text>
      </>
    );
  }
};
