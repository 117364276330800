import React, { JSX } from 'react';
import { WarningIcon } from '@chakra-ui/icons';
import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { IMAGE_FILE_MAX_SIZE_IN_BYTES } from './ImagePreviewValidation.consts';

export const isFileTooLarge = (fileSize: number): boolean => {
  return fileSize > IMAGE_FILE_MAX_SIZE_IN_BYTES;
};

type ImageSizeValidationProps = {
  handleDeleteUploadedImage: () => void;
  imageSizePreview: number;
  imageNamePreview: string;
};

export const ImagePreviewValidation = ({
  handleDeleteUploadedImage,
  imageSizePreview,
  imageNamePreview,
}: ImageSizeValidationProps): JSX.Element => {
  const translations = useTranslations();

  return (
    <VStack alignItems="start">
      {isFileTooLarge(imageSizePreview) && (
        <Flex alignItems="center" bgColor={'red.100'} my={2} p={2}>
          <WarningIcon color={'red.500'} w={10} h={6} />
          <Text ml={2}>
            <b>{translations('image_upload_error_message')}</b>
            {translations('image_upload_error_message_explanation')}
          </Text>
        </Flex>
      )}
      <Flex alignItems="stretch" width="100%">
        {isFileTooLarge(imageSizePreview) && <WarningIcon color={'red.500'} w={10} h={6} />}
        <Text flex={1} m={2}>
          {imageNamePreview}
        </Text>
        <Button variant="redSolid" onClick={handleDeleteUploadedImage}>
          {translations('image_upload_remove_button')}
        </Button>
      </Flex>
    </VStack>
  );
};
