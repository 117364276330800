import React, { useMemo } from 'react';
import { ColumnDef } from '@tanstack/react-table';

import { TableType } from '../../../../../../common/components/Table/model/enums/table-type.enum';
import { Table } from '../../../../../../common/components/Table/Table';
import { useTranslations } from '../../../../../../contexts/LocalizationContext';
import { useLocalizationContext } from '../../../../../../contexts/LocalizationContext/useLocalizationContext.hook';
import { ReturnUnknownRelevosInformationRequest } from '../../../../model/return-unknown-relevos-request';
import { UnknownRelevosListResponse } from '../../../../model/unknown-relevos-list-response';

type ReturnUnknownItemsConfirmModalBodyProps = {
  itemsToReturn: ReturnUnknownRelevosInformationRequest[];
  unknownItems?: UnknownRelevosListResponse[];
};

export const ReturnUnknownItemsConfirmModalBody = ({
  itemsToReturn,
  unknownItems,
}: ReturnUnknownItemsConfirmModalBodyProps) => {
  const translations = useTranslations();
  const { language } = useLocalizationContext();

  const columns = useMemo<ColumnDef<ReturnUnknownRelevosInformationRequest>[]>(
    () => [
      {
        header: translations('category_list_category'),
        cell: ({ row }) => (
          <>
            {
              unknownItems?.find((unknownItem) => unknownItem.category.id === row.original.categoryId)?.category
                .translations[language]
            }
          </>
        ),
      },
      {
        id: 'user',
        header: translations('category_list_user'),
        cell: ({ row }) => (
          <>{unknownItems?.find((unknownItem) => unknownItem.user.id === row.original.userId)?.user.firstName}</>
        ),
      },
      { accessorKey: 'quantity', header: translations('category_list_quantity') },
    ],
    [language, translations, unknownItems],
  );

  return (
    <Table
      type={TableType.SIMPLE}
      data={itemsToReturn.filter((item) => !!item.quantity)}
      columns={columns}
      variant="relevoSummary"
    />
  );
};
