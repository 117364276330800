import React from 'react';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { useTranslations } from 'contexts/LocalizationContext';

import { ErrorMessages } from '../../common/components/ErrorMessages/ErrorMessages';
import { useRetterRelevo, useUpdateRetterRelevo } from '../../services/retter-relevos';
import { mapRetterRelevoResponseToFormModel } from './form/retter-relevo-form';
import { RetterRelevosEditingDisabledModal } from './modals/RetterRelevosEditingDisabledModal';
import { RetterRelevoRequest } from './model/retter-relevo-request';
import { RetterRelevoForm } from './RetterRelevoForm';

interface EditRetterRelevoProps {
  retterRelevoId: string;
  canEditOffer: boolean;
  shouldEditBeVisible: boolean;
}

export const EditRetterRelevo = ({ retterRelevoId, canEditOffer, shouldEditBeVisible }: EditRetterRelevoProps) => {
  const translations = useTranslations();
  const { isOpen: isEditModalOpen, onClose: onEditModalClose, onOpen: onEditModalOpen } = useDisclosure();
  const {
    isOpen: isModalEditingDisabledOpen,
    onClose: onModalEditingDisabledClose,
    onOpen: onModalEditingDisabledOpen,
  } = useDisclosure();
  const {
    data: retterRelevo,
    isLoading: isLoadingRetterRelevo,
    isFetching: isFetchingRetterRelevo,
    refetch: fetchRetterRelevo,
  } = useRetterRelevo(retterRelevoId);
  const {
    mutate: updateRetterRelevo,
    isLoading: isUpdatingRetterRelevos,
    isError: isUpdateRetterRelevoError,
  } = useUpdateRetterRelevo();

  const handleEditClick = async () => {
    if (!canEditOffer) {
      onModalEditingDisabledOpen();
      return;
    }
    try {
      await fetchRetterRelevo();
      onEditModalOpen();
    } catch (error) {
      console.error('Error fetching retterRelevo:', error);
    }
  };

  const onSubmit = (retterRelevoRequest: RetterRelevoRequest) => {
    updateRetterRelevo(
      { retterRelevoId, retterRelevoRequest },
      {
        onSuccess: () => {
          onEditModalClose();
        },
        onError: (err) => {
          toast.error(<ErrorMessages errors={err} />);
        },
      },
    );
  };

  return (
    <>
      {shouldEditBeVisible ? (
        <Box opacity={canEditOffer ? 1 : 0.4}>
          <Button size="sm" variant="blackSolid" ml={2} onClick={handleEditClick}>
            {translations('retter_relevos_edit')}
          </Button>
        </Box>
      ) : null}
      <Modal isOpen={isEditModalOpen} onClose={onEditModalClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent h="auto" maxH="80%" minH="20%" position="fixed" bottom="1%" right="1%" transform="none">
          <ModalHeader>{translations('retter_relevos_create_modal_header_edit')}</ModalHeader>
          <ModalCloseButton />
          <Flex h="100%" flexDirection="column" px={5} mb={5} flex="1" overflowY="auto">
            <RetterRelevoForm
              isLoading={isLoadingRetterRelevo || isFetchingRetterRelevo || isUpdatingRetterRelevos}
              isError={isUpdateRetterRelevoError}
              confirmButtonText={translations('retter_relevo_edit_submit_button')}
              model={retterRelevo ? mapRetterRelevoResponseToFormModel(retterRelevo) : undefined}
              onSubmit={onSubmit}
              onCancel={onEditModalClose}
            />
          </Flex>
        </ModalContent>
      </Modal>
      <RetterRelevosEditingDisabledModal isOpen={isModalEditingDisabledOpen} onClose={onModalEditingDisabledClose} />
    </>
  );
};
